.solution-box {
  width: 10rem;
  padding: 1rem;

  @media #{$xs-layout} {
  margin: 0!important;
  padding:1rem;
  }
  .back-image {
    width: 100%;
    height: 15rem;
    margin-bottom: 0.5rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  h3 {
    color: $theme-color;
  }
  p {
    font-family: $montregular;
    color: #888888;
  }
  a,
  button {
    background-color: $theme-color;
    color: #fff;
    border: none;
    font-family: $montregular;
    padding: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    &:hover {
      background-color: $secundary-color;
      border: none;
      animation: none;
      color: $theme-color;
    }
  }
}

.w-30 {
  width: 30%;
}

.m-20 {
  margin: 20px;
}

.color-theme {
  margin-top: 1rem;
  color: $theme-color;
}

.f-size-18 {
  font-size: 18px;
}

.slider-solution {
  h4 {
    width: 50%;
    padding: 1rem;
    height: fit-content;
    font-size: 20px;
    background-color: rgb(255, 255, 255, 0.8);
    color: $theme-color;
    text-align: center;

    @media #{$xs-layout} {
      width: 100%;
    }
  }
}
